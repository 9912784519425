import React, {useState, useEffect} from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore/lite';
import {Typography, Box, Stack, CircularProgress, IconButton} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import db from '../../firebase';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IOS_DOWNLOAD_LINK, ANDROID_DOWNLOAD_LINK } from "../../utils/Constants";
import { DownloadAppFooter } from "../../components/DownloadAppFooter";

export const AvuiPage = () => {
    interface esdevenimentDB {
        id?: string;
        title?: string;
        desc?: string;
        image?: string;
        price?: string;
        location?:string;
        category?: string;
        date?: dateObjectDB;
        mes_info?: string;
        info?: string;
        url?: string;
        buy_link?: string;
        docs?: Array<string>  
        contact?: {
            whats?: string;
            tel?: string;
            email?: string;
            link?: string;
            address?: string;
            buy_link?: string;
        }
        status?: string;
        published_date?: Date;
        modified_date?: Date;
        group?: number;
        original?: boolean;
        multiDay?: boolean;
        }

    interface dateObjectDB {
    string?:Array<string> ;
    init?: number;
    end?: number;
    }

    const [events, setEvents] = useState<esdevenimentDB[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [daysOfPrintedWeek, setDaysOfPrintedWeek] = useState<Date[]>([]);
    const DIES: {[id: string] : string }= {
        0: "DLL",
        1: "DM",
        2: "DX",
        3: "DJ",
        4: "DV",
        5: "DSS",
        6: "DU"
      }

      const SHORT_MONTHS_DICTIONARY: {[id: string] : string } = {
        0: "Gen",
        1: "Feb",
        2: "Mar",
        3: "Abr",
        4: "Mai",
        5: "Jun",
        6: "Jul",
        7: "Ago",
        8: "Set",
        9: "Oct",
        10: "Nov",
        11: "Des"
    }

    const startDay = 1; //0=sunday, 1=monday etc.
    const now = new Date()
    const d = now.getDay()
    const weekStart = new Date(now.valueOf() - (d<=0 ? 7-startDay : d-startDay )*86400000); //rewind to start day
    const weekEnd = new Date(weekStart.valueOf() + 6*86400000); //add 6 days to get last day

    useEffect(() => {
        if (daysOfPrintedWeek.length === 0) {
            const days = [];
            for (let i = 1; i < 8; i++) {
              days.push(new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() - weekStart.getDay() + i))
            }
            setDaysOfPrintedWeek(days) 
        }

    }, [weekEnd]);

    useEffect(() => {
        const now00 = new Date(now.setHours(0, 0, 0)).getTime()/1000
        const now23 = new Date(now.setHours(23, 59, 59)).getTime()/1000

        const getEventsMultiDay = async () => {
            try {
                const eventsCol = query(collection(db, "events"), where("date.end", ">=", now00), where("status", "==", "PUBLISHED"),where("multiDay", "==", true));
                const eventsSnapshot = await getDocs(eventsCol);
                const eventsList = eventsSnapshot.docs.map(doc => doc.data());
                setEvents(prevEvents => [...prevEvents,...eventsList] );
                setIsLoading(false)
            } catch (error) {
                console.log(error)
            }
        };
        const getEventsNoMultiDay = async () => {
            try {
              const eventsCol = query(collection(db, "events"), where("date.init", ">=", now00), where("date.init", "<=", now23), where("status", "==", "PUBLISHED"),where("multiDay", "==", false));
              const eventsSnapshot = await getDocs(eventsCol);
              const eventsList = eventsSnapshot.docs.map(doc => doc.data());
              setEvents(eventsList)
              getEventsMultiDay();
            } catch (error) {
              console.log(error)
            } finally {
              setIsLoading(false);
            }
        };
        
        getEventsNoMultiDay()
      }, []);

      function isToday(day: Date) {
        if (now !== undefined && day !== undefined) {
          return day.getFullYear() === now.getFullYear() && day.getMonth() === now.getMonth() && day.getDate() === now.getDate();
        }
      }

      const dayClick = (_e:React.MouseEvent<HTMLElement>, isToday: boolean) => {
        console.log("click");
        console.log(isToday)
      };


    function printDateHeader(unixDate: Date) {
        var date = new Date(unixDate);
        return `${date.getDate()} ${SHORT_MONTHS_DICTIONARY[date.getMonth()]}.`
    }

  return (
    <Box>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <IconButton>
                <ArrowBackIcon />
            </IconButton>
            <Typography>
                {daysOfPrintedWeek[0] && printDateHeader(daysOfPrintedWeek[0])} - {daysOfPrintedWeek[6] && printDateHeader(daysOfPrintedWeek[6])}
            </Typography>
            <IconButton>
                <ArrowForwardIcon />
            </IconButton>
        </Stack>
        <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around", alignItems: "center" }}>
            {daysOfPrintedWeek.map((day, index) => (
                <Box key={index} onClick={(e) => dayClick(e, isToday(day)!)}>
                    <Stack direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
                        <Typography style={{color: isToday(day) ? "darkorchid" : "black"}}>{DIES[index]}</Typography>
                        <Typography>{day.getDate()}</Typography>
                    </Stack>
                </Box>
            ))}
        </Stack>
        {isLoading ? 
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        :
            events.map((event) => (
                <Grid container spacing={2} key={event.id}>
                    <Grid xs={12} sm={6} md={4} mdOffset={1} sx={{px:{xs:0, sm: 2}, py:{xs:0, sm: 3}}}>
                        <img src={event.image} width="100%" alt="event image"/>
                    </Grid>
                    <Grid xs={12} sm={6} md={6} sx={{py:{xs:0, sm: 2}, px:{xs:2}}}>
                        <Typography>{event.title}</Typography>
                    </Grid>
                </Grid>
            ))
        }
    </Box>
  ) 
};